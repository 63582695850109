import * as React from "react"

const Footer = () => (
    <footer className="bg-gray-800 text-gray-300 px-6 lg:px-8 py-12">
    <div className="max-w-screen-xl mx-auto mb-12 lg:mb-16" >
    <svg xmlns="http://www.w3.org/2000/svg" width="87.434" height="30.157" viewBox="0 0 228 64">
    <defs>
      <filter id="filter" x="0.469" y="0.844" width="227.062" height="62.312" filterUnits="userSpaceOnUse">
        <feFlood result="flood" floodColor="#cacaca"/>
        <feComposite result="composite" operator="in" in2="SourceGraphic"/>
        <feBlend result="blend" in2="SourceGraphic"/>
      </filter>
    </defs>
    <path id="iodine" fill-rule="evenodd" filter="url(#filter)" d="M6.533,14.981A5.991,5.991,0,1,0,6.533,3,6.051,6.051,0,0,0,.462,8.99,6.051,6.051,0,0,0,6.533,14.981ZM1.421,62.345H11.644V22.09H1.421V62.345Zm39.856,0.8a20.929,20.929,0,1,0,0-41.853c-12.141,0-21.326,9.265-21.326,20.927S29.137,63.144,41.277,63.144Zm0-9.585a11.3,11.3,0,1,1,0-22.6A11.3,11.3,0,0,1,41.277,53.559Zm46.566,9.585a16.752,16.752,0,0,0,13.339-5.991l0.24,5.192h9.664V0.843H100.7v25.8a16.678,16.678,0,0,0-12.86-5.511c-11.262,0-20.128,9.345-20.128,21.007C67.716,53.879,76.581,63.144,87.843,63.144ZM89.6,53.959A11.528,11.528,0,0,1,78.1,42.138a11.5,11.5,0,1,1,23,0A11.577,11.577,0,0,1,89.6,53.959ZM126.98,14.981a5.991,5.991,0,1,0-6.07-5.99A6,6,0,0,0,126.98,14.981Zm-5.112,47.364h10.224V22.09H121.868V62.345Zm21.086,0h10.3V37.585a7.3,7.3,0,0,1,7.588-7.189,7.031,7.031,0,0,1,7.268,7.189v24.76H178.5V36.307c0-8.387-6.39-15.016-14.457-15.016a15.166,15.166,0,0,0-10.783,4.473V22.09h-10.3V62.345Zm84.585-20.527c-0.16-12.3-9.1-20.527-20.687-20.527-12.3,0-21.246,9.265-21.246,20.927s8.786,20.927,21.566,20.927c8.466,0,15.255-4.153,18.53-10.783L217.4,48.927a11.694,11.694,0,0,1-10.3,5.831c-5.911,0-10.543-4.153-11.342-10.7h31.709Zm-20.607-12.3c4.712,0,7.907,2.556,9.585,6.629H196.788A10.526,10.526,0,0,1,206.932,29.518Z"/>
  </svg>
    </div>
    <div className="max-w-screen-xl mx-auto grid md:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-x-8">
      <div>
        <h1 className="text-xl font-semibold text-white">Main Menu</h1>
        <nav className="mt-4">
          <ul className="space-y-2">
            <li>
              <a href="https://iodine.gg/discord" className="font-normal text-base hover:text-gray-400 transition duration-200 ease-in-out">Discord</a>
            </li>
            <li>
              <a href="https://status.iodine.gg" className="font-normal text-base hover:text-gray-400 transition duration-200 ease-in-out">Service Status</a>
            </li>
            <li>
              <a href="https://github.com/iodineimg/transparency" className="font-normal text-base hover:text-gray-400 transition duration-200 ease-in-out">Transparency</a>
            </li>
            <li>
              <a href="https://docs.iodine.gg" className="font-normal text-base hover:text-gray-400 transition duration-200 ease-in-out">Documentation</a>
            </li>
          </ul>
        </nav>
      </div>
      <div>
        <h1 className="text-xl font-semibold text-white">Legal</h1>
        <nav className="mt-4">
          <ul className="space-y-2">
            <li>
              <a href="https://iodine.gg/terms" className="font-normal text-base hover:text-gray-400 transition duration-200 ease-in-out">Terms and Conditions</a>
            </li>
            <li>
              <a href="https://iodine.gg/privacy" className="font-normal text-base hover:text-gray-400 transition duration-200 ease-in-out">Privacy Policy</a>
            </li>
            <li>
              <a href="mailto:abuse@iodine.gg" className="font-normal text-base hover:text-gray-400 transition duration-200 ease-in-out">Abuse</a>
            </li>
            <li>
              <a href="mailto:privacy@iodine.gg" className="font-normal text-base hover:text-gray-400 transition duration-200 ease-in-out">Request Your Data</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row justify-between items-center space-y-4 mt-16 lg:mt-20 border-t-2 border-gray-700 pt-8 ">
      <div className="text-sm space-y-4 md:space-y-1 text-center md:text-left">
        <p>&copy; Copyright 2021 <strong>Iodine Project</strong>. All Rights Reserved.</p>
        <p>Made with love by Noodles and Wowjesus.</p>
      </div>
    </div>
  </footer>
    );
    export default Footer;