import * as React from "react"
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import Helmet from "react-helmet"
import Hero from "../images/hero.svg"
import Favicon from "../images/favicon.ico"
import Manifest from "../ext/manifest.json"

const navigation = [
    { name: 'Home', href: '/', current: true },
    { name: 'Discord', href: 'https://iodine.gg/discord', current: false },
    { name: 'Status', href: 'https://status.iodine.gg', current: false },
    { name: 'Abuse', href: 'mailto:abuse@iodine.gg', current: false },
  ]

  const mobilenav = [
    { name: 'Home', href: 'https://iodine.gg', current: true },
    { name: 'Discord', href: 'https://iodine.gg/discord', current: false },
    { name: 'Status', href: 'https://status.iodine.gg', current: false },
    { name: 'Abuse', href: 'mailto:abuse@iodine.gg', current: false },
    { name: 'Docs', href: 'https://docs.iodine.gg', current: false },
    { name: 'Login', href: 'https://dash.iodine.gg/login', current: false },
    { name: 'Register', href: 'https://dash.iodine.gg/register', current: false },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

export default function Header() {

    return (
      <Disclosure as="nav" className="bg-gray-800">
            {({ open }) => (
              <>
              <Helmet htmlAttributes={{ lang: 'en' }}>
                <title>Iodine — Home</title>
                <link rel="icon" href={Favicon} /> 
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" /> 
                <meta name="theme-color" content="#5F5D94" /> 
                <meta name="description" content="Iodine is a blazing fast, free and reliable screenshot and file host." /> 
                <meta name="keywords" content="iodine, iodine project, iodine images, iodine gg, iodine screenshot, iodine images, iodine host, iodine pictures, iodine.gg, iodine file host" />
                <meta name="og:type" content="website" />  
                <meta name="og:title" content="iodine.gg" /> 
                <meta name="og:description" content="Iodine is a blazing fast, free and reliable screenshot and file host. A convenient and secure place for storing or serving your content." />
                <meta name="og:url" content="https://iodine.gg" /> 
                <meta name="og:image" content="https://cdn.iodine.gg/assets/frontend/img/logo.png" />
                <link rel="dns-prefetch" href="https://i.iodine.gg" />
                <link rel="preload" as="image" href={Hero} />
                <link rel="canonical" href="https://iodine.gg"/>
                <link
                href="https://i.iodine.gg/g53b2.png"
                rel="icon"
                type="image/png"
                sizes="16x16"
                />
                <link
                href="https://i.iodine.gg/7878j.png"
                rel="icon"
                type="image/png"
                sizes="32x32"
               />
              <link rel="apple-touch-icon" href="https://i.iodine.gg/cbg40.png" />
            </Helmet>
                <div className="max-w-1xl md:mx-5 px-2 sm:px-6 lg:px-8">
                  <div className="relative flex items-center justify-between h-16">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                      {/* Mobile menu button*/}
                      <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                    <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                      <div className="flex-shrink-0 flex md:ml-1 md:mt-0.5">
                        <a href="https://iodine.gg" aria-label="Iodine Logo">
                      <svg xmlns="http://www.w3.org/2000/svg" width="87.434" height="30.157" viewBox="0 0 228 64">
        <path id="iodine" fill-rule="evenodd" filter="url(#filter)" d="M6.533,14.981A5.991,5.991,0,1,0,6.533,3,6.051,6.051,0,0,0,.462,8.99,6.051,6.051,0,0,0,6.533,14.981ZM1.421,62.345H11.644V22.09H1.421V62.345Zm39.856,0.8a20.929,20.929,0,1,0,0-41.853c-12.141,0-21.326,9.265-21.326,20.927S29.137,63.144,41.277,63.144Zm0-9.585a11.3,11.3,0,1,1,0-22.6A11.3,11.3,0,0,1,41.277,53.559Zm46.566,9.585a16.752,16.752,0,0,0,13.339-5.991l0.24,5.192h9.664V0.843H100.7v25.8a16.678,16.678,0,0,0-12.86-5.511c-11.262,0-20.128,9.345-20.128,21.007C67.716,53.879,76.581,63.144,87.843,63.144ZM89.6,53.959A11.528,11.528,0,0,1,78.1,42.138a11.5,11.5,0,1,1,23,0A11.577,11.577,0,0,1,89.6,53.959ZM126.98,14.981a5.991,5.991,0,1,0-6.07-5.99A6,6,0,0,0,126.98,14.981Zm-5.112,47.364h10.224V22.09H121.868V62.345Zm21.086,0h10.3V37.585a7.3,7.3,0,0,1,7.588-7.189,7.031,7.031,0,0,1,7.268,7.189v24.76H178.5V36.307c0-8.387-6.39-15.016-14.457-15.016a15.166,15.166,0,0,0-10.783,4.473V22.09h-10.3V62.345Zm84.585-20.527c-0.16-12.3-9.1-20.527-20.687-20.527-12.3,0-21.246,9.265-21.246,20.927s8.786,20.927,21.566,20.927c8.466,0,15.255-4.153,18.53-10.783L217.4,48.927a11.694,11.694,0,0,1-10.3,5.831c-5.911,0-10.543-4.153-11.342-10.7h31.709Zm-20.607-12.3c4.712,0,7.907,2.556,9.585,6.629H196.788A10.526,10.526,0,0,1,206.932,29.518Z"/>
      </svg>
      </a>
                      </div>
                      <div className="hidden sm:block sm:ml-6">
                        <div className="flex space-x-4">
                          {navigation.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 transition duration-200 ease-in-out hover:text-white',
                                'px-3 py-2 rounded-md text-sm font-medium'
                              )}
                              aria-current={item.current ? 'page' : undefined}
                            >
                              {item.name}
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="hidden sm:flex items-center justify-end md:flex-1 lg:w-0">
                <a href="https://dash.iodine.gg/login" className="whitespace-nowrap inline-flex items-center justify-center ml-5 px-2 py-1 md:px-3 md:py-1 border border-indigo-400 rounded-md shadow-sm text-sm font-medium text-indigo-400 bg-transparent hover: border-indigo-600 hover:bg-indigo-600 hover:text-white transition duration-200 ease-in-out">
                  Login
                </a>
                <a
                  href="https://dash.iodine.gg/register"
                  className="ml-4 whitespace-nowrap inline-flex items-center justify-center ml-1 px-2 py-1 md:px-3 md:py-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 transition duration-200 ease-in-out"
                >
                  Register
                </a>
              </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    </div>
                  </div>
                </div>
                <div>
                </div>
                <Disclosure.Panel className="sm:hidden">
                  <div className="px-2 pt-2 pb-3 space-y-1">
                    {mobilenav.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'block px-3 py-2 rounded-md text-base font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </Disclosure.Panel>
          </>
      )}
    </Disclosure>
  )
}